body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
	height: 100vh;
	width: 100vw;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

a {
	color: inherit;
	text-decoration: none;
}

#root {
	overflow: hidden;
	height: inherit;
}

.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80%;
	min-width: 600px;
	background-color: white;
	border-radius: 10px;
	padding: 20px;
	outline: 0;
}
.error {
	color: red;
}

.icon {
	cursor: pointer;
	color: #1565c0;
}

.icon-disabled {
	color: #aaaaaa;
}

.icon-loading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 400px;
}

.striped-table {
	thead {
		tr {
			background-color: rgba(0, 0, 0, 0.45);
			th {
				color: #ffffff;
				font-weight: 700;
			}
		}
	}

	tbody {
		tr {
			&:nth-of-type(odd) {
				background-color: rgba(0, 0, 0, 0.04);
			}
			// hide last border
			&:last-child td,
			&:last-child th {
				border: 0;
			}
		}
	}
}

.inner-table {
	thead {
		tr {
			background-color: rgba(0, 0, 0, 0.45) !important;
			th {
				color: #ffffff;
				font-weight: 700;
			}
		}
	}

	tbody {
		tr {
			&:nth-of-type(odd) {
				background-color: rgba(0, 0, 0, 0.04);
			}
			// hide last border
			&:last-child td,
			&:last-child th {
				border: 0;
			}
		}
	}
}
